import React from 'react';

// Incubators Data
const incubators = [
  { id: 1, name: "Maker Village", image: "/assets/incubator/Makervillage.webp" },
  { id: 2, name: "KAU RABI", image: "/assets/incubator/rabi.webp" },
  { id: 3, name: "KSUM", image: "/assets/incubator/ksum.webp" },
  { id: 4, name: "Digital University Kerala", image: "/assets/incubator/duk.webp" },
  { id: 5, name: "NAARM", image: "/assets/incubator/naarm.webp" },
  { id: 6, name: "ICAR", image: "/assets/incubator/ikar.webp" },
];

// Advisors Data
const advisors = [
  { name: 'Dr Anilakumar', designation: 'Amala Cancer Care Institute', image: '/assets/team/Dr-Anilakumar.webp' },
  { name: 'Dr Snany Surendran', designation: 'MVR Cancer Care Institute', image: '/assets/team/Snany.webp' },
  { name: 'Dr Reshmi', designation: 'Seed Farm Director, Alathur, Kerala', image: '/assets/team/no.png' },
];

// Team Data
const team = [
  { name: 'Pradeep Kumar P', designation: 'Chief Executive Officer', image: '/assets/team/pradeep.webp' },
  { name: 'Nidheesh V', designation: 'Chief Technical Officer', image: '/assets/team/nidheesh.webp' },
  { name: 'Arun Babu V', designation: 'Chief Application Developer', image: '/assets/team/arun.webp' },
  { name: 'Deepthi A', designation: 'Chief Operations Officer', image: '/assets/team/deepthi.webp' },
  { name: 'Anas Y', designation: 'Backend Developer', image: '/assets/team/anas.webp' },
  { name: 'S Athuldas', designation: 'Frontend Developer', image: '/assets/team/athul.webp' },
  { name: 'Prajith A K', designation: 'Chief Creative Head', image: '/assets/team/prajith1.webp' },
  { name: 'Pradeep R', designation: 'Manufacturing Coordinator', image: '/assets/team/pradeep1.webp' },
];

// Reusable section heading component
const SectionHeading = ({ title, subtitle }) => (
  <div className="mb-12 text-center">
    <h2 className="mb-3 text-4xl font-extrabold">
      <span className="text-transparent bg-gradient-to-r from-blue-600 to-teal-400 bg-clip-text">
        {title}
      </span>
    </h2>
    {subtitle && <p className="text-sm text-gray-300 opacity-80">{subtitle}</p>}
    <div className="w-24 h-1 mx-auto mt-4 rounded-full bg-gradient-to-r from-blue-600 to-teal-400"></div>
  </div>
);

// Reusable card component for team members and advisors
const PersonCard = ({ image, name, designation }) => (
  <div className="flex flex-col items-center group">
    <div className="relative mb-4 transition-all duration-500 transform group-hover:scale-105">
      <div className="w-32 h-32 overflow-hidden border-2 border-teal-400 rounded-full shadow-lg group-hover:shadow-teal-400/50">
        <div 
          className="w-full h-full bg-center bg-cover"
          style={{ backgroundImage: `url(${image})` }}
        ></div>
      </div>
      <div className="absolute inset-0 transition-all rounded-full bg-gradient-to-b from-transparent to-black/40 group-hover:from-transparent group-hover:to-black/60"></div>
    </div>
    <h3 className="mb-1 text-lg font-bold text-white">{name}</h3>
    <p className="text-sm text-teal-300 opacity-90">{designation}</p>
  </div>
);

// Incubator card component
const IncubatorCard = ({ image, name }) => (
  <div className="flex flex-col items-center group">
    <div className="relative mb-3 transition-all duration-500 transform group-hover:scale-105">
      <div className="overflow-hidden border-2 border-blue-400 rounded-full shadow-lg w-28 h-28 group-hover:shadow-blue-400/50">
        <div 
          className="w-full h-full bg-center bg-cover"
          style={{ backgroundImage: `url(${image})` }}
        ></div>
      </div>
      <div className="absolute inset-0 transition-all rounded-full bg-gradient-to-b from-transparent to-black/40 group-hover:from-transparent group-hover:to-black/60"></div>
    </div>
    <h3 className="text-base font-bold text-center text-white">{name}</h3>
  </div>
);

const Enablers = () => {
  return (
    <div className="min-h-screen p-4 md:p-10 ">
      <div className="mx-auto max-w-7xl">
        {/* Incubators Section */}
        <section className="mb-24">
          <SectionHeading title="Enablers" subtitle="Caax and Krishisat" />
          
          <div className="relative">
            {/* Decorative elements */}
            <div className="absolute inset-0 bg-blue-500/5 rounded-3xl -z-10 blur-3xl"></div>
            
            <div className="grid grid-cols-2 gap-6 md:grid-cols-3 lg:grid-cols-6 md:gap-8">
              {incubators.map((incubator) => (
                <IncubatorCard 
                  key={incubator.id} 
                  image={incubator.image} 
                  name={incubator.name} 
                />
              ))}
            </div>
          </div>
        </section>

        {/* Advisors Section */}
        <section className="mb-24">
          <SectionHeading title="Meet Our Advisors" />
          
          <div className="relative">
            {/* Decorative elements */}
            <div className="absolute inset-0 bg-teal-500/5 rounded-3xl -z-10 blur-3xl"></div>
            
            <div className="grid grid-cols-1 gap-12 md:grid-cols-3">
              {advisors.map((advisor, index) => (
                <PersonCard 
                  key={index} 
                  image={advisor.image} 
                  name={advisor.name} 
                  designation={advisor.designation} 
                />
              ))}
            </div>
          </div>
        </section>

        {/* Team Section */}
        <section className="mb-16">
          <SectionHeading title="Meet Our Team" />
          
          <div className="relative">
            {/* Decorative elements */}
            <div className="absolute inset-0 bg-blue-500/5 rounded-3xl rotate-3 -z-10 blur-3xl"></div>
            <div className="absolute inset-0 bg-teal-500/5 rounded-3xl -rotate-3 -z-10 blur-3xl"></div>
            
            <div className="grid grid-cols-1 gap-10 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 md:gap-12">
              {team.map((member, index) => (
                <PersonCard 
                  key={index} 
                  image={member.image} 
                  name={member.name} 
                  designation={member.designation} 
                />
              ))}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Enablers;